import React from 'react';
import {
    BooleanInput,
    composeValidators,
    Create,
    DateInput,
    DeleteButton,
    Edit,
    FileField,
    FileInput,
    ImageField,
    ImageInput,
    maxValue,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    usePermissions,
    useRecordContext
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {useCharityChoices} from "../lib/customHooks";
import hasPermission from "../auth/hasPermission";
import moment from "moment";
import {Button} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import {useNavigate} from "react-router-dom";

const ChildFormFields = ({create}) => {
    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    // To be able to access the record we use the record context hook
    const record = useRecordContext();

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // We'll want to access the theme
    const theme = useTheme();

    const navigate = useNavigate();

    // A volunteer belongs to a specific charity, retrieve the options here
    const charityChoices = useCharityChoices();

    // We need the choices before we can continue
    if (!charityChoices) return null;

    // This is a functional component that renders a custom toolbar for React Admin
    // It displays a Save button and a Delete button if the user has the necessary permission and the record is not sponsored
    const CustomToolbar = props => {
        return (
            <Toolbar
                sx={{display: 'flex', justifyContent: 'space-between'}}
            >
                <SaveButton/>
                {hasPermission(permissions, ["match"], "guardians") &&
                    <Button
                        onClick={() => {
                            navigate('/guardians/create', {state: {child: record}});
                        }}
                        sx={{
                            color: 'white',
                            backgroundColor: theme.palette.secondary.main,
                            '&:hover': {backgroundColor: 'darkgreen'}
                        }}
                    >
                        {t('Add New Sponsor')}
                    </Button>
                }
                {hasPermission(permissions, ["delete"], "children.delete")
                    && record && !record.child_is_sponsored &&
                    <DeleteButton/>
                }
            </Toolbar>
        );
    }

    const CharitySelectInput = ({ charityChoices }) => {
        // Determine default value: use the ID of the single charity if only one choice exists
        const defaultValue = charityChoices.length === 1 ? charityChoices[0].id : undefined;

        return (
            <SelectInput
                label={t('Charity')}
                source="charity_id"
                choices={charityChoices}
                validate={required()}
                defaultValue={defaultValue} // Conditionally set defaultValue
            />
        );
    };


    // This is a validator function to validate the age of a child
    // It is composed of multiple validators: required, minValue, and maxValue
    // The maximum age is 20 years (set in minValue ;-) ), and the minimum age should be lower than the current date
    const validateAge = composeValidators(
        required(),
        maxValue(moment().format('YYYY-MM-DD'))
    );

    return (
        <SimpleForm redirect="show" toolbar={<CustomToolbar/>}>
            {hasPermission(permissions, ["create", "edit"], "charityChoices") && (
                <CharitySelectInput charityChoices={charityChoices} />
            )}
            {hasPermission(permissions, ["show"], "umbrella") &&
                <BooleanInput label='Project level' source="project_level"/>
            }
            {!create && hasPermission(permissions, ["create", "edit"], "children.verified") &&
                <BooleanInput label={t('Verified')} source="verified"/>
            }
            <TextInput label={t('First name')} source="first_name" validate={required()}/>
            <TextInput label={t('Last name')} source="last_name" validate={required()}/>
            <DateInput label={t('Birth date')} source="birth_date" validate={validateAge}/>
            <SelectInput label={t('Gender')} source="gender" choices={[
                {id: 'MALE', name: t('Male')},
                {id: 'FEMALE', name: t('Female')},
                {id: 'OTHER', name: t('Other')},
            ]} validate={required()}
            />
            <SelectInput label={t('Contact frequency')} source="contact_frequency" choices={[
                {id: 'DAILY', name: t('DAILY')},
                {id: 'WEEKLY', name: t('WEEKLY')},
                {id: 'MONTHLY', name: t('MONTHLY')},
                {id: 'QUARTERLY', name: t('QUARTERLY')},
                {id: 'SEMI_ANNUALLY', name: t('SEMI_ANNUALLY')},
                {id: 'ANNUALLY', name: t('ANNUALLY')},
            ]} defaultValue="MONTHLY"
            />
            <TextInput multiline fullWidth label={t("What do I dream of? What makes me happy?")} source="intro"
                       validate={required()}/>
            <TextInput multiline fullWidth label={t('My story')} source="family" validate={required()}/>
            <TextInput multiline fullWidth label={t('Why do I need help')} source="help" validate={required()}/>
            <ImageInput labelSingle={`${t('Click to update profile image')} (< 3MB)`} source="profile_image"
                        label={t('Profile image')} maxSize={3000000} validate={required()} accept="image/*">
                <ImageField source="src" title={t('Profile image')}/>
            </ImageInput>
            <FileInput labelSingle={t('Click to update video image')} source="profile_video"
                       label={t('Introduction video')} accept="video/*">
                <FileField source="src" title="Introduction video"/>
            </FileInput>
        </SimpleForm>
    );
}

export const ChildEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit child profile')} redirect="show">
            <ChildFormFields create={false}/>
        </Edit>
    );
}

export const ChildCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create the basic child details')}>
            <ChildFormFields create={true}/>
        </Create>
    );
}

