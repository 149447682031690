import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useTranslation} from "react-i18next";

export default function ChildTranslationTable({translations}) {

    const {t} = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="translation table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Language')}</TableCell>
                        <TableCell>{t('Intro')}</TableCell>
                        <TableCell>{t('Family')}</TableCell>
                        <TableCell>{t('Help')}</TableCell>
                        <TableCell>{t('Original')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {translations.map((translation) => (
                        <TableRow
                            key={translation.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {translation.languageCode}
                            </TableCell>
                            <TableCell>{translation.intro}</TableCell>
                            <TableCell>{translation.family}</TableCell>
                            <TableCell>{translation.help}</TableCell>
                            <TableCell>{translation.original ? t('True') : t('')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
