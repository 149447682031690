import React from 'react';
import {Admin, fetchUtils, resolveBrowserLocale, Resource} from 'react-admin';
// defaultTheme
import theme from './themes';
import authProvider from './auth/provider';
import Dashboard from './dashboard';
import springDataRest from "./dataprovider/springDataRest";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import russianMessages from 'ra-language-russian';
import armenianMessages from 'ra-language-armenian';
import germanMessages from 'ra-language-german';
import brasilMessages from 'ra-language-pt-br';

import CharityList from "./charity/CharityList";
import {CharityCreate, CharityEdit} from "./charity/CharityCRUD";
// project imports
import {
    AppErrorsIcon, BellDashboardIcon,
    CharityDashboardIcon,
    ChildDashboardIcon, ChildPostsStatsIcon, ExportSettingsIcon,
    GuardianDashboardIcon, MailLogsIcon, MailSettingsIcon,
    PostsDashboardIcon, UserActionLogsIcon,
    VolunteersDashboardIcon
} from "./icons/icons";
import {useTranslation} from "react-i18next";
import CharityShow from "./charity/CharityShow";
import {NofamLayout} from "./layout/Layout";
import hasPermission from "./auth/hasPermission";
import VolunteerList from "./volunteer/VolunteerList";
import {VolunteerCreate, VolunteerEdit} from "./volunteer/VolunteerCRUD";
import VolunteerShow from "./volunteer/VolunteerShow";
import ChildList from "./children/ChildList";
import ChildShow from "./children/ChildShow";
import {ChildCreate, ChildEdit} from "./children/ChildCRUD";
import GuardianList from "./guardian/GuardianList";
import {GuardianCreate, GuardianEdit} from "./guardian/GuardianCRUD";
import GuardianShow from "./guardian/GuardianShow";
import ActivityList from "./activity/ActivityList";
import PostList from "./posts/PostList";
import {PostShow} from "./posts/PostShow";
import {PostCreate, PostEdit} from "./posts/PostCRUD";
import ChildUpdatePostStatsList from "./childUpdatePostStats/ChildUpdatePostStatsList";
import MailSettingsList from "./mail/MailSettingsList";
import {MailSettingsCreate, MailSettingsEdit} from "./mail/MailSettingsCRUD";
import {MailSettingsShow} from "./mail/MailSettingsShow";
import MailLogsList from "./mailLogs/MailLogsList";
import MailLogsShow from "./mailLogs/MailLogsShow";
import ExportSettingsList from "./exportSettings/ExportSettingsList";
import {ExportSettingsCreate, ExportSettingsEdit} from "./exportSettings/ExportSettingsCRUD";
import AppErrorsList from "./appErrors/AppErrorsList";
import AppErrorsShow from "./appErrors/AppErrorsShow";
import UserActionLogsList from "./userActionLogs/UserActionLogsList";
import UserActionLogsShow from "./userActionLogs/UserActionLogsShow";
import UmbrellaResource from "./umbrella/UmbrellaResource";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const accessToken = localStorage.getItem('accessToken');
    options.headers.set('Authorization', 'Bearer ' + accessToken);
    return fetchUtils.fetchJson(url, options);
};

const messages = {
    'en': englishMessages,
    'nl': dutchMessages,
    'ru': russianMessages,
    'hy': armenianMessages,
    'de': germanMessages,
    'pt': brasilMessages
};

console.log(resolveBrowserLocale());

const dataProvider = springDataRest(`${process.env.REACT_APP_API_URL}/api/v1`, httpClient);
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale(),
    {allowMissing: true}
);

const App = () => {
    const {t} = useTranslation();

    return (
        <Admin
            dashboard={Dashboard}
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={authProvider}
            layout={NofamLayout}
            theme={theme}
        >
            {permissions => (
                <>
                    {hasPermission(permissions, ['*'], 'umbrellas') &&
                        <Resource {...UmbrellaResource} />
                    }

                    <Resource options={{label: t('Charities')}} name="charities" icon={CharityDashboardIcon}
                              list={hasPermission(permissions, ['list'], 'charities') ? CharityList : null}
                              create={hasPermission(permissions, ['create'], 'charities') ? CharityCreate : null}
                              edit={hasPermission(permissions, ['edit'], 'charities') ? CharityEdit : null}
                              show={hasPermission(permissions, ['show'], 'charities') ? CharityShow : null}
                    />

                    <Resource options={{label: t('Volunteers')}} name="volunteers" icon={VolunteersDashboardIcon}
                              list={hasPermission(permissions, ['list'], 'volunteers') ? VolunteerList : null}
                              create={hasPermission(permissions, ['create'], 'volunteers') ? VolunteerCreate : null}
                              edit={hasPermission(permissions, ['edit'], 'volunteers') ? VolunteerEdit : null}
                              show={hasPermission(permissions, ['show'], 'volunteers') ? VolunteerShow : null}
                    />

                    {hasPermission(permissions, ['*'], 'children') && (
                        <Resource options={{label: t('Children')}} name="children" icon={ChildDashboardIcon}
                                  list={ChildList} show={ChildShow} edit={ChildEdit} create={ChildCreate}
                        />
                    )}

                    <Resource options={{label: t('Guardians')}} name="guardians" icon={GuardianDashboardIcon}
                              list={hasPermission(permissions, ['list'], 'guardians') ? GuardianList : null}
                              create={hasPermission(permissions, ['create'], 'guardians') ? GuardianCreate : null}
                              edit={hasPermission(permissions, ['edit'], 'guardians') ? GuardianEdit : null}
                              show={hasPermission(permissions, ['show'], 'guardians') ? GuardianShow : null}
                    />

                    {hasPermission(permissions, ['*'], 'posts') && (
                        <Resource options={{label: t('Posts')}} name="posts" icon={PostsDashboardIcon}
                                  list={PostList} show={PostShow} edit={PostEdit} create={PostCreate}
                        />
                    )}

                    {hasPermission(permissions, ['*'], 'childUpdatePostStats') && (
                        <Resource options={{label: t('Child post stats')}} name="childUpdatePostStats"
                                  list={ChildUpdatePostStatsList} icon={ChildPostsStatsIcon}
                        />
                    )}

                    {hasPermission(permissions, ['*'], 'mailSettings') && (
                        <Resource options={{label: t('Mail Settings')}} name="mailSettings"
                                  list={MailSettingsList} create={MailSettingsCreate} edit={MailSettingsEdit}
                                  show={MailSettingsShow} icon={MailSettingsIcon}
                        />
                    )}
                    {hasPermission(permissions, ['*'], 'mailLogs') &&
                        <Resource options={{label: t('Mail Logs')}} name="mailLogs"
                                  list={MailLogsList} show={MailLogsShow} icon={MailLogsIcon}
                        />
                    }

                    {hasPermission(permissions, ['*'], 'activities') && (
                        <Resource options={{label: t('Notifications')}} name="activities" list={ActivityList}
                        icon={BellDashboardIcon}/>
                    )}

                    {hasPermission(permissions, ['*'], 'exportSettings') && (
                        <Resource options={{label: t('Export Settings')}} name="exportSettings"
                                  list={ExportSettingsList} create={ExportSettingsCreate} edit={ExportSettingsEdit}
                                  show={ExportSettingsEdit} icon={ExportSettingsIcon}
                        />
                    )}

                    {hasPermission(permissions, ['*'], 'errors') &&
                        <Resource options={{label: t('App Errors')}} name="errors"
                                  list={AppErrorsList} show={AppErrorsShow} icon={AppErrorsIcon}
                        />}
                    {hasPermission(permissions, ['*'], 'userActionLogs') &&
                        <Resource options={{label: t('User Action Logs')}} name="userActionLogs"
                                  list={UserActionLogsList} show={UserActionLogsShow} icon={UserActionLogsIcon}
                        />}
                </>
            )}
        </Admin>
    );
}

export default App;

