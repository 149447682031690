import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTableCell-head": {
            fontWeight: "bold",
            textTransform: "capitalize",
        },
        "& .MuiTableCell-body": {
            maxWidth: 300,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },
}));

