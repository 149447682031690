const rolePermissions = [
    {
        admin: [
            {action: ['*'], resource: '*'}
        ]
    },
    {
        umbrella: [
            // Resources high level
            {action: ['*'], resource: 'activities'},
            {action: ['*'], resource: 'charities'},
            {action: ['*'], resource: 'children'},
            {action: ['*'], resource: 'posts'},
            {action: ['*'], resource: 'mailSettings'},
            {action: ['*'], resource: 'mailLogs'},
            {action: ['*'], resource: 'childUpdatePostStats'},
            {action: ['list', 'show', 'create', 'edit', 'detach', 'export_details', 'match'], resource: 'guardians'},
            {action: ['list', 'show', 'create', 'edit', 'delete'], resource: 'volunteers'},
            {action: ['admin'], resource: 'dashboard'},

            // Detail level
            {action: ['*'], resource: 'charityChoices'},
            {action: ['*'], resource: 'charity.location'},
            {action: ['view'], resource: 'charity.donations'},
            {action: ['default'], resource: 'charityFilter'},
            {action: ['*'], resource: 'children.charity_name'},
            {action: ['create', 'edit'], resource: 'children.verified'},
            {action: ['delete'], resource: 'children.delete'},
            {action: ['delete'], resource: 'post.comment'},
        ],
    },
    {
        charity: [
            // Resources high level
            {action: ['*'], resource: 'children'},
            {action: ['*'], resource: 'posts'},
            {action: ['*'], resource: 'childUpdatePostStats'},
            {action: ['list', 'show'], resource: 'guardians'},
            {action: ['charity'], resource: 'dashboard'},

            // Detail level
            {action: ['*'], resource: 'charityChoices'},
            {action: ['default'], resource: 'charityFilter'},
            {action: ['view'], resource: 'charity.donations'},
            {action: ['*'], resource: 'children.charity_name'},
            {type: 'deny', action: ['create', 'edit'], resource: 'children.verified'},
            {type: 'deny', action: ['*'], resource: 'children.verified'},
        ],
    },
    {
        volunteer: [
            // Resources high level
            {action: ['*'], resource: 'children'},
            {action: ['*'], resource: 'posts'},
            {action: ['*'], resource: 'childUpdatePostStats'},
            {action: ['list', 'show'], resource: 'guardians'},
            {action: ['charity'], resource: 'dashboard'},

            // Detail level
            {action: ['*'], resource: 'charityChoices'},
            {action: ['default'], resource: 'charityFilter'},
            {action: ['*'], resource: 'children.charity_name'},
            {type: 'deny', action: ['create', 'edit'], resource: 'children.verified'},
            {type: 'deny', action: ['*'], resource: 'children.verified'},
        ],
    },
];


/**
 * Checks whether a role has permission to perform an action on a resource.
 * @param {string} role - The role to check.
 * @param {string[]} actions - The actions to check.
 * @param {string} resource - The resource to check.
 * @returns {boolean} - Whether the role has permission to perform the actions on the resource.
 */
const hasPermission = (role, actions, resource) => {
    // Find the permissions for the given role
    const permissionsForRole = rolePermissions.find(
        (permission) => Object.keys(permission)[0] === role
    )[role];

    // Check for deny permissions
    const denyPermission = permissionsForRole.find(
        (permission) =>
            permission.type === 'deny' &&
            permission.resource === resource &&
            permission.action.some((action) => actions.includes(action))
    );
    if (denyPermission) {
        return false;
    }

    // Check for matching permissions
    return permissionsForRole.find(
        (permission) =>
            // Allow access if the action and resource are both *
            (permission.action.includes('*') && permission.resource === '*') ||
            // Allow access if the resource matches and the action includes either * or includes the specified action
            (permission.resource === resource &&
                (permission.action.includes('*') || permission.action.some((action) => actions.includes(action))))
    );
};


export default hasPermission;
