import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    Create,
    DateInput,
    DeleteButton,
    Edit,
    email,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    usePermissions,
    useRecordContext,
    WithRecord
} from 'react-admin';
import {Avatar, Box, Button, Typography} from "@mui/material";
import {useTranslation} from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {axiosApiInstance} from "../AxiosUtils";
import hasPermission from "../auth/hasPermission";
import {useLocation} from 'react-router-dom';
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

/**
 * A list of children that are sponsored by this guardian
 * @returns {*}
 * @constructor
 */
const GuardianFormFields = ({create}) => {
    const {t} = useTranslation();

    const {permissions} = usePermissions();

    const record = useRecordContext();

    // To be used in the case that we are creating a guardian
    const location = useLocation();
    const [newChild, setNewChild] = useState(null);
    const [childrenToDisplay, setChildrenToDisplay] = useState([]);

    // Function to load children
    useEffect(() => {
        let initialChildren = [];

        if (record && record.children && record.children.length > 0) {
            initialChildren = [...record.children];
        }

        if (newChild) {
            initialChildren.push(newChild);
        }

        setChildrenToDisplay(initialChildren);
    }, [record, newChild]);

    const [showAreYouSureAlert, setShowAreYouSureAlert] = useState(false);
    const [detachedSuccessFully, setDetachedSuccessFully] = useState(false);
    const [triggeredDetach, setTriggeredDetach] = useState(false);
    const [guardianId, setGuardianId] = useState(0);
    const [detachChildId, setDetachChildId] = useState(0);

    // Initial state matches the create state (enabled for new, disabled for update)
    const [isEmailEditable, setEmailEditable] = useState(create);

    useEffect(() => {
        const childData = location.state?.child;
        if (childData) {
            setNewChild(childData);
        }
    }, [location]);

    const handleClickOpen = (guardianId, childId) => {
        setTriggeredDetach(false);
        setGuardianId(guardianId);
        setDetachChildId(childId);
        setShowAreYouSureAlert(true);
    };

    const handleClose = () => {
        setShowAreYouSureAlert(false);
        setTriggeredDetach(false);
    };

    const removeChildFromGuardian = () => {
        setTriggeredDetach(true);
        const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
        axiosApiInstance.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/guardians/${guardianId}/child/${detachChildId}`
        ).then((response) => {
            if (response.status === 200) {
                // Update state to remove the detached child or refetch children list
                setChildrenToDisplay(currentChildren =>
                    currentChildren.filter(child => child.id !== detachChildId)
                );

                setShowAreYouSureAlert(false);
                setDetachedSuccessFully(true);
            }
        }).catch((reason) => {
            setShowAreYouSureAlert(false);
            setDetachedSuccessFully(false);
        })
    }

    const getSponsoredChildren = (create) => {
        return childrenToDisplay.map((child, i) => {
            return <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: 16
            }} key={i}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                    <Avatar alt={child.name}
                            src={`${process.env.REACT_APP_CDN_URL}/${child.profile_image}.${child.image_ext}`}
                            sx={{width: 56, height: 56}} style={{marginRight: 16}}/>
                    <Typography component="h6" variant="h6">{child.first_name} {child.last_name}</Typography>
                    {!create && hasPermission(permissions, ["detach"], "guardians") &&
                        <Button size="small" variant="outlined" style={{marginLeft: 16, fontWeight: 700}}
                                onClick={() => handleClickOpen(record.id, child.id)}>
                            <Box p={1}>
                                {t('Detach from child')}
                            </Box>
                        </Button>
                    }

                </div>
            </div>
        });
    }

    // This is a functional component that renders a custom toolbar for React Admin
    // It displays a Save button and a Delete button if the user has the necessary permission and the record is not sponsored
    const CustomToolbar = props => (
        <Toolbar
            {...props}
            sx={{display: 'flex', justifyContent: 'space-between'}}
        >
            <SaveButton/>
            {permissions === 'admin' &&
                <DeleteButton/>
            }
        </Toolbar>
    );

    // We'll need a record if we want to update a guardian
    if (!create && !record) return null;

    return (
        <div className="showie">
            {create &&
                <Typography sx={{fontWeight: 'bold', fontSize: 20, mb: 2}}>
                    {t('Create sponsor')}
                </Typography>
            }
            {!create &&
                <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                    <Grid item xs={12}>
                        <div className="custom-box borderGreen">
                            <WithRecord
                                render={record =>
                                    <div className="rowit">
                                        <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                                src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>
                                        <div>
                                            <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                                {t('Edit')}: {record.salutation ? t(record.salutation) : ''} {record.given_name} {record.family_name}
                                            </Typography>
                                            {record.relation_number && record.relation_number !== '' && record.relation_number !== null &&
                                                <Typography sx={{
                                                    fontWeight: 400,
                                                    fontSize: '16px',
                                                    fontStyle: 'italic',
                                                }}>({t('Relation number')} : {record.relation_number})</Typography>
                                            }
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            }
            <SimpleForm redirect="show" toolbar={<CustomToolbar/>}>
                <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                    <Grid item xs={12} lg={6} xl={6}>
                        {!create &&
                            <div className="custom-box">
                                <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BooleanInput source="enabled" label={t('Enabled')}
                                                      helperText={t('Enabled helper text')}/>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <div class="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Contact details')}</Typography>
                            <Grid container spacing={2}>
                                {!create &&
                                    <Grid item xs={12} lg={12} xl={4}>
                                        <SelectInput label={t('Salutation')} source="salutation" choices={[
                                            {id: 'mr', name: t('salutation_mr')},
                                            {id: 'mrs', name: t('salutation_mrs')},
                                            {id: 'family', name: t('salutation_family')},
                                        ]} validate={required()} sx={{marginRight: '6px'}}/>
                                    </Grid>
                                }
                                <Grid item xs={6} lg={6} xl={4}>
                                    <TextInput label={t('First name')} source="given_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={6} lg={6} xl={4}>
                                    <TextInput label={t('Last name')} source="family_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={12} sx={{flexDirection: "row", alignItems: 'center'}}>
                                    <TextInput source="email" label={t('email')} validate={[required(), email()]}
                                               sx={{width: 350}}
                                               disabled={!isEmailEditable}
                                               helperText={!create ? t('Edit email helper text') : ''}/>
                                    {!create &&
                                        <>
                                            <label>&nbsp;{t('Make editable')}</label>
                                            <input
                                                id="triggerEmailUpdate"
                                                type="checkbox"
                                                checked={isEmailEditable}
                                                onChange={() => setEmailEditable(!isEmailEditable)}
                                            />
                                        </>
                                    }
                                </Grid>
                                {!create &&
                                    <Grid item xs={6}>
                                        <TextInput source="phone" label={t('Phone')}/>
                                    </Grid>
                                }
                                {hasPermission(permissions, ["create"], "guardians") && create &&
                                    <Grid item xs={6}>
                                        <TextInput label={t('Tmp password')} source="password" validate={required()}
                                                   sx={{mt: 3}} defaultValue="TempWw@Nf"/>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                        {!create &&
                            <div className="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Address')}</Typography>
                                {hasPermission(permissions, ["export_details"], "guardians") &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SelectInput label={t('Country')} source="country"
                                                         choices={[
                                                             {id: 'HY', name: t('country_hy')},
                                                             {id: 'DE', name: t('country_de')},
                                                             {id: 'EN', name: t('country_en')},
                                                             {id: 'FR', name: t('country_fr')},
                                                             {id: 'IT', name: t('country_it')},
                                                             {id: 'NL', name: t('country_nl')},
                                                             {id: 'RU', name: t('country_ru')},
                                                             {id: 'ES', name: t('country_es')},
                                                             {id: 'US', name: t('country_us')},
                                                         ]}
                                                         defaultValue={"NL"}
                                                         validate={required()}
                                            />
                                        </Grid>
                                        <Grid item xs={6} xl={6}>
                                            <TextInput source="street" label={t('Street')}/>
                                        </Grid>
                                        <Grid item xs={4} xl={6}>
                                            <TextInput source="house_number" label={t('House number')}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextInput source="postal_code" label={t('Postal code')}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="city" label={t('City')} sx={{marginRight: '6px'}}/>
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        }
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <div class="custom-box">
                            <Typography variant="h6" gutterBottom>{t('Sponsor info')}</Typography>
                            <Grid container spacing={2} lg={12}>
                                <Grid item lg={12}>
                                    <Typography style={{fontWeight: 500, fontSize: 12}}>
                                        {create && t('Becomes guardian of')}
                                        {!create && t('Guardian of')}
                                    </Typography>
                                    {create && !newChild &&
                                        <Alert severity="warning" sx={{mb: 2}}>
                                            <AlertTitle>{t('Attention')}</AlertTitle>
                                            {t('First select a child')}
                                        </Alert>
                                    }
                                    {!create && getSponsoredChildren()}
                                    {create && newChild &&
                                        <TextInput label={t('Child')} source="child_id" defaultValue={newChild.id}
                                                   disabled={true}/>
                                    }
                                    {triggeredDetach && detachedSuccessFully &&
                                        <Alert severity="success" sx={{mb: 2}}>
                                            <AlertTitle>{t('Child detached title')}</AlertTitle>
                                            {t('Successfully detached child')}
                                        </Alert>
                                    }
                                    {triggeredDetach && !detachedSuccessFully &&
                                        <Alert severity="warning" sx={{mb: 2}}>
                                            <AlertTitle>{t('Child detached title')}</AlertTitle>
                                            {t('Failed detaching child')}
                                        </Alert>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        {!create && hasPermission(permissions, ["export_details"], "guardians") &&
                            <>
                                <div class="custom-box" style={{marginTop: '24px'}}>
                                    <Typography variant="h6" gutterBottom>{t('Sponsor payment info')}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5} xl={4}>
                                            <SelectInput label={t('Donation method')} source="donation_method"
                                                         choices={[
                                                             {id: 'iban', name: t('IBAN')},
                                                             {id: 'invoice', name: t('Invoice')},
                                                             {id: 'stripe', name: 'Stripe'},
                                                             {id: 'via_parent', name: t('Via parent')},
                                                             {id: 'other', name: t('Other')}
                                                         ]} validate={required()}/>
                                        </Grid>
                                        <Grid item xs={7} xl={4}>
                                            <TextInput source="iban" label={t('IBAN')}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} xl={4}>
                                            <TextInput label={t('Direct debit reference')}
                                                       source="direct_debit_reference"/>
                                        </Grid>
                                        <Grid item xs={6} xl={4}>
                                            <DateInput label={t('Direct debit start date')}
                                                       source="direct_debit_start_date"/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} xl={4}>
                                            <BooleanInput source="sponsor_as_company" label={t('Sponsor as company')}/>
                                        </Grid>
                                        <Grid item xs={6} xl={4}>
                                            <TextInput source="company" label={t('Company')}/>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="custom-box" style={{marginTop: '24px'}}>
                                    <Typography variant="h6" gutterBottom>{t('Other info')}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextInput multiline fullWidth source="note" label={t('Note')} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        }
                    </Grid>
                    <div>
                        <Dialog open={showAreYouSureAlert} onClose={handleClose}
                                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {t('Detach alert title')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('Detach alert message')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} autoFocus>{t('No, cancel')}</Button>
                                <Button onClick={removeChildFromGuardian}>{t('Yes, detach')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                </Grid>
            </SimpleForm>
        </div>
    )
}

export const GuardianCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create sponsor')}>
            <GuardianFormFields create={true}/>
        </Create>
    )
};

export const GuardianEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit sponsor')} redirect="show">
            <GuardianFormFields create={false}/>
        </Edit>
    )
};
