import React from 'react';
import {
    FunctionField,
    NumberField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    TextField,
    UrlField,
    WithRecord
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Avatar, Typography} from "@mui/material";

const UmbrellaShow = (props) => {
    const {t} = useTranslation();

    return (
        <Show {...props}>
            <SimpleShowLayout sx={{backgroundColor: "#84ba3f", color: "#FFF"}}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 100, width: 100}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.logo_name}.${record.ext}`}/>}/>
                />
                <WithRecord
                    render={record => <Typography component="h4" variant="h4">{record.charity_name}</Typography>}/>
            </SimpleShowLayout>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <TextField source="email" label={t('email')}/>
                    <UrlField source="payment_link_url"/>
                    <TextField source="city" label={t('City')}/>
                    <TextField source="country" label={t('Country')}/>
                    <TextField multiline fullWidth source="description" label={t('Description')}/>
                    <NumberField source="active" label={t('Is active')}/>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>

        </Show>
    );
}

export default UmbrellaShow;
