import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import getCroppedImg from '../lib/getCroppedImg'; // Helper function to crop and create a file

const CropImageModal = ({ open, onClose, imageUrl, onSave }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const handleCropComplete = useCallback((_, croppedArea) => {
        setCroppedAreaPixels(croppedArea);
    }, []);

    const handleSaveCrop = async () => {
        try {
            const croppedImageBlob = await getCroppedImg(imageUrl, croppedAreaPixels);
            const file = new File([croppedImageBlob], 'cropped_profile_image.jpg', { type: 'image/jpeg' });

            onSave(file); // Pass the cropped image file to the parent component for upload
        } catch (error) {
            console.error('Error cropping the image:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth PaperProps={{ style: { height: '80vh' } }}>
            <DialogTitle>Crop Image</DialogTitle>
            <DialogContent style={{ position: 'relative', height: '60vh', backgroundColor: '#f0f0f0' }}>
                {imageUrl && (
                    <Cropper
                        image={imageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={9 / 6} // Set the aspect ratio
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={handleCropComplete}
                        style={{ containerStyle: { zIndex: 1300 } }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSaveCrop} color="primary">
                    Save Crop
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CropImageModal;
